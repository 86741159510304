import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';
import { valueType } from '@/utilities/constants';

const URI = 'admin';

export const list = (isActive = '') => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;
  const tokenV3 = store.state.auth.tokenV3;

  /* @description requisita lista de usuários das contas do administrador logado
   * @param {id} adm_id id do usuário administrador logado
   * @param {id} brand id da marca selecionada
   * @param {number} isActive determina se devem ser retornados apenas os usuários ativos (1), apenas inativos (0) ou ambos (vazio)
   */
  const url = `${process.env.VUE_APP_ROOT_API_V3}/${URI}/getUserList`;

  return axios({
    method: 'get',
    headers: { Authorization: `Bearer ${tokenV3}` },
    url,
    params: {
      adm_id: user.id,
      brand: brand.id,
      active: isActive,
      account_id: brand.id,
    },
  });
};

export const getUserDetailInfo = id => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;
  const tokenV3 = store.state.auth.tokenV3;

  /* @description requisita dados de um usuário específico
   * @param {id} brand id da marca selecionada
   * @param {id} admId id do usuário administrador logado
   * @param {id} userId id do usuário administrador logado
   */
  const url = `${URI}/getUserDetailInfo`;

  return axios({
    method: 'get',
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    url,
    params: {
      brand: brand.id,
      admId: user.id,
      userId: id,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Admin-updateUser
 * @param {String} name User's fullname
 * @param {String} email User's e-mail login
 * @param {String} accessLevel User's access level
 * @param {Number} clientId Client identification
 * @param {String} routes Comma separated list of route codes user has access to.
 * @param {String} stores List of accounts the user has access to ex: 1:A,B
 * @param {Number} user_360 Access to Pmweb 360°. 0 = no access. 1 = full access. 2 = app-only access.
 */
export const create = (name, email, accessLevel, clientId, routes, stores, user_360) => {
  const tokenV3 = store.state.auth.tokenV3;
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI}/createUser`,
    validateStatus: () => true,
    params: {
      name: name,
      login: email,
      lang: 'pt-BR',
      access: accessLevel,
      clientId: clientId,
      routes: routes,
      accounts: stores,
      user360: user_360,
      user_enabled: 1,
    },
  });
};

export const edit = (name, email, client, accessLevel, userID, routes, stores, user_360) => {
  // const { user } = store.getters;
  /* @description cria um novo usuário
   * @param {id} adm_id id do usuário administrador logado
   * @param {id} user_id id do usuário
   * @param {id} client_id id do cliente
   * @param {string} user_name nome do usuario
   * @param {string} user_login email do usuário
   * @param {string} user_lang idioma do usuário (pt-BR,en,es)
   * @param {number} user_enabled determina se o usuário sera habilitado (1) ou desabilitado (0)
   * @param {number} user_groups nível de permissão do usuário
   * @param {Array} user_routes Comma separated list of route codes user has access to.
   * @param {Array} user_accounts
   * @param {number} user_360 Access to Pmweb 360°. 0 = no access. 1 = full access. 2 = app-only access.
   */
  const tokenV3 = store.state.auth.tokenV3;
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI}/updateUser`,
    validateStatus: () => true,
    params: {
      lang: 'pt-BR',
      name: name,
      login: email,
      clientId: client,
      access: accessLevel,
      userId: userID,
      routes: routes,
      accounts: stores,
      user360: user_360,
    },
  });
};

export const updateLanguage = language => {
  const { user } = store.getters;
  const tokenV3 = store.state.auth.tokenV3;

  /* @description atualiza linguagem do usuario logado
   * @param {id} userId id do usuário
   * @param {string} lang idioma do usuário (pt-BR,en,es)
   */
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI}/updateUser`,
    params: {
      lang: language,
      userId: user.id,
    },
  });
};

export const disable = (userId, action) => {
  const tokenV3 = store.state.auth.tokenV3;

  /* @description habilita/desabilita usuário
   * @param {id} userId id do usuário a ser atualizado
   * @param {number} enabled determina se o usuário deve ser ativado (1) ou desativado (0)
   */
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI}/updateUser`,
    validateStatus: () => true,
    params: {
      enabled: action,
      userId: userId,
    },
  });
};

export const validate = {
  field(type, value) {
    if (!value) return false;

    if (type === valueType.string) return this.test.string(value);
    if (type === 'email') return this.test.email(value);
    if (type === valueType.integer) return this.test.integer(value);

    return true;
  },
  fields(items) {
    return items.every(item => this.field(...item));
  },
  test: {
    email(str) {
      // eslint-disable-next-line
      const regex =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(str);
    },
    string(str) {
      return str.length;
    },
    integer(str) {
      return Number.isInteger(str);
    },
  },
};

export const getGroups = () => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;
  /* @description requisita lista de grupos de usuário (permissões)
   * @param {id} adm_id id do usuário administrador logado
   * @param {id} brand id da marca selecionada
   */
  return axios.get(`${URI}/get_groups`, {
    params: {
      adm_id: user.id,
      brand: brand.id,
    },
  });
};

export const changePassword = password =>
  /* @description atualiza senha do usuário logado
   * @param {string} user_password nova senha
   */
  axios.get(`${URI}/password_update`, {
    params: {
      user_password: password,
    },
  });

/**
 *  @description get qrcode image from Google API's
 *  @param {Number=} level pixel per point
 *  @param {Number=} size outer frame
 */
export const generateQrCodeMfa = params => {
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: 'token/generateQrcodeMfa',
    params: {
      ...params,
    },
  });
};

export const generateQrCode = url => {
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url,
    responseType: 'blob',
  });
};

/**
 *  @description validate token
 *  @param {Number} code Code generated by google authenticator
 */
export const setValidToken = code => {
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: 'token/activeAuthenticationMfa',
    params: {
      verification_code: code,
    },
  });
};

/**
 *  @description put disable MFA
 */
export const disableMFA = () => {
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: 'token/disableAuthenticationMfa',
  });
};

/**
 *  @description return is MFA is active
 */
export const getStatusMFA = () => {
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: 'token/statusAuthenticationMfa',
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Admin-resetPassword
 * @description reset user password
 */
export const postResetPassword = userId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  return axios.post(
    `${URI}/resetPassword`,
    {
      brand: brand.id,
      token,
      userId,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};

export const getUnlockUser = ({ targetId }) => {
  const tokenV3 = store.state.auth.tokenV3;
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: 'user/unlock',
    data: {
      targetId,
    },
  });
};
