import { RemoteLimitsResponse, Limit } from '../models/limit';
import { getLimits } from '../services';

export const fetchLimits = () =>
  getLimits().then(response => {
    if (!response?.data) throw new Error('An unknown error ocurred');
    return formatResponse(response?.data);
  });

const formatResponse = (response: RemoteLimitsResponse): Limit[] =>
  response?.data?.map((limit: Limit) => ({
    title: limit.title,
    value: {
      hired: limit.value[0],
      used: limit.value[1],
    },
    type: limit.type,
    tooltip: limit.tooltip,
  }));
