<template>
  <div class="select wrap">
    <div class="input-wrapper">
      <input
        id="columns-search"
        v-model="term"
        type="text"
        class="no-click"
        :class="{ error: !$parent.columnValid }"
        :placeholder="$t('select')"
        autocomplete="off"
        :data-cy="include ? 'select-audience-condition-include' : 'select-audience-condition-exclude'"
        @focus="openDropdown"
        @click="openDropdown"
        @blur="blur"
        @input="triggerDebounceReset"
      />
      <i v-show="isCloseVisible" :class="'icon icon-close'" data-clear-input @click="clearField"></i>
      <i v-show="isMainIconVisible" :class="`icon icon-${showColumns ? 'search' : 'select-12px'}`"></i>
    </div>
    <div
      v-show="!$parent.columnValid"
      class="error-alert icon icon-attention-14px"
      v-tooltip="{
        theme: 'error',
        triggers: ['hover'],
        content: $t('audiences.value-error'),
        distance: 8,
        html: true,
      }"
    ></div>
    <div v-show="showColumns" class="columns-list no-click">
      <div class="list no-click">
        <ul v-if="filtered.length > 0" class="groups customScrollBar no-click">
          <li v-for="group in filtered" :key="group.key" class="group no-click" :data-value="group.key">
            <strong>{{ group.key }}</strong>
            <ul v-if="!!group.columns.length" class="columns">
              <li
                v-for="column in group.columns"
                :key="column.key"
                class="column no-click"
                :data-value="column.key"
                :data-cy="include ? `${column.key}-include` : `${column.key}-exclude`"
                @click="change"
                @mouseover="triggerDebounceReset"
                v-html="column.name"
              ></li>
            </ul>
          </li>
        </ul>
        <p v-else class="pl-3 pr-3 pt-2">{{ $t('audiences.no-results') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { validate } from '@/helpers/audiences';
import { stringParser } from '@/helpers/string-parser.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    include: {
      type: Boolean,
      default: null,
    },
    group: {
      type: Number,
      default: null,
    },
    serie: {
      type: Number,
      default: null,
    },
    updateColumnInfo: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      term: undefined,
      showColumns: false,
      isCloseVisible: false,
      isMainIconVisible: true,
      dataProp: {},
    };
  },
  computed: {
    ...mapGetters('audience', ['columnOptions']),
    filtered() {
      let groups = this.columnOptions;
      if (this.term) {
        groups = [];
        this.columnOptions.forEach(group => {
          const columnsFiltered = group.columns.filter(column =>
            stringParser(column.name).includes(stringParser(this.term)),
          );
          if (columnsFiltered.length) {
            groups.push({ key: group.key, columns: columnsFiltered });
          }
        });
      }
      return groups;
    },
    selectedColumnName() {
      let columnName;
      if (this.data.column) {
        this.triggerClearField();
        this.columnOptions.forEach(group => {
          const filter = group.columns.filter(col => col.key === this.data.column)[0];
          if (filter) columnName = filter.name;
        });
      }
      return columnName;
    },
  },
  created() {
    bus.$on('validate-fields', data => {
      if (data.include === this.include && data.group === this.group) this.validate();
    });
  },
  mounted() {
    this.dataProp = this.data;

    if (this.data.column) {
      this.term = this.selectedColumnName;
    }

    this.hideDropdown();

    bus.$on('clear-term', () => {
      this.clearField();
      this.term = undefined;
    });
  },

  beforeUnmount() {
    bus.$off('validate-fields');
    bus.$off('clear-term');
  },
  methods: {
    ...mapActions('audience', ['triggerDebounceReset']),
    triggerClearField() {
      this.isMainIconVisible = false;
      this.isCloseVisible = !this.isMainIconVisible;
    },
    clearField() {
      this.term = undefined;
      this.isMainIconVisible = true;
      this.isCloseVisible = !this.isMainIconVisible;
    },
    blur() {
      setTimeout(() => {
        if (this.term !== this.selectedColumnName) {
          this.term = undefined;
          this.dataProp.column = undefined;
        }
      }, 500);
    },
    validate() {
      const result = validate.field(null, null, this.data.column);
      this.$parent.columnValid = result;
    },
    change(event) {
      this.term = event.target.textContent;
      this.dataProp['column'] = event.target.dataset.value;
      this.showColumns = false;
      // validate column
      this.validate();

      if (this.$parent.columnValid) {
        this.updateColumnInfo(this.data.column);
        bus.$emit('audience-column-changed');
        this.triggerDebounceReset(true);
      }
    },
    openDropdown() {
      this.showColumns = true;
      this.triggerDebounceReset(true);
    },
    hideDropdown() {
      const body = document.querySelector('body');

      body.addEventListener('click', event => {
        const className = event.target?.className;

        if (typeof className === 'string') {
          if (!className?.includes('no-click')) {
            this.showColumns = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: 100%;
  position: relative;
  input {
    cursor: pointer;
  }
  .icon {
    position: absolute;
    right: 12px;
    display: block;

    &:not(.icon-close) {
      pointer-events: none;
    }

    &.icon-select-12px {
      top: 10px;
      font-size: 14px;
      color: $gray-700;
    }
    &.icon-search {
      right: 5px;
      top: 1px;
      color: $oto-omni;
    }
    &.icon-close {
      position: absolute;
      right: 6px;
      top: 3px;
      padding: 0;
      color: $gray-700;
      cursor: pointer;
    }
  }
}
.wrap {
  position: relative;
}
.columns-list {
  width: 100%;
  padding-top: 35px;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 0;
  border: 1px solid $gray-500;
  border-top: 0;
  border-radius: 18px;
  z-index: 100;
  .list {
    height: 100%;
    overflow: hidden;
    background-color: $color-white;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
  }
  ul {
    list-style: none;
    padding: 0;
    pointer-events: auto;

    font-size: 12px;
    color: $gray-700;
    line-height: 26px;

    &.groups {
      max-height: 310px;
      overflow-y: auto;
      margin: 10px 0;
    }
    &.columns {
      margin: 0;
      li {
        padding: 0 15px;
        &:hover {
          background-color: $gray-300;
          cursor: pointer;
        }
      }
    }
  }
  strong {
    padding: 0 15px;
  }
}
</style>
