<template>
  <el-dialog v-model="isVisible" :id="id">
    <form v-if="!loadingModal" class="actions-message">
      <div class="ac-form">
        <h2>{{ $t('360.actions.new.message') }}</h2>

        <div class="row mt-3">
          <div class="col-6">
            <!-- scope -->
            <div class="actions-message__scope form-group">
              <label>{{ $t('360.actions.scope') }}</label>
              <v-select
                v-model="scope"
                label="name"
                class="w-100"
                :clearable="false"
                :reduce="item => item.id"
                :options="scopeList"
                :placeholder="$t('select')"
                @option:selected="changeScope"
              >
                <template #option="{ name }">
                  {{ $tc(`${name}`, 3) }}
                </template>
                <template #selected-option="{ name }">
                  {{ $tc(`${name}`, 3) }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes" class="arrow-down"></span>
                </template>
                <template #no-options>
                  {{ $t('no-results') }}
                </template>
              </v-select>
            </div>
          </div>

          <!-- scope / value -->
          <div class="col-6">
            <label>{{ $t('360.actions.value') }}</label>
            <v-select
              v-model="scopeValue"
              label="name"
              :clearable="false"
              :loading="loadingScope"
              :disabled="checkScopeBrand()"
              :options="scopeValueList"
              :placeholder="$t('select')"
            >
              <template #option="{ name }">
                <span :title="$tc(name, 1)">{{ $tc(name, 1) }}</span>
              </template>
              <template #selected-option="{ name }">
                {{ $tc(`${name}`, 1) }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('no-results') }}
              </template>
            </v-select>
          </div>
        </div>

        <div class="row mt-2">
          <!-- channel -->
          <div class="col-6">
            <label>{{ $t('360.campaigns.contact-medium') }}</label>
            <v-select
              v-model="channel"
              :reduce="item => item.id"
              label="name"
              :clearable="false"
              :options="channelTypes"
              :placeholder="$t('select')"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('no-results') }}
              </template>
            </v-select>
          </div>
        </div>

        <!-- dragndrop area -->
        <div class="container drag-drop">
          <label>{{ $t('360.message') }}</label>
          <textarea
            ref="textarea"
            v-model="text"
            name="message"
            cols="30"
            rows="3"
            autofocus
            data-cy="form-message"
            @dragover="focusTextarea"
            :placeholder="$t('360.actions.placeholder.textarea')"
            :disabled="disableTextarea"
          ></textarea>
          <div v-if="loaderRenderMessage" class="dd__loader">
            <base-loader />
          </div>
          <div class="view-edit">
            <div>
              <span v-if="!viewOtherMessage && !loaderRenderMessage" @click="fetchRenderMessage()">{{
                $t('360.actions.preview')
              }}</span>
              <span v-if="viewOtherMessage && !loaderRenderMessage" @click="fetchRenderMessage()">{{
                $t('360.actions.view-other-message')
              }}</span>
              <span v-if="disableTextarea" @click="enableTextarea()">{{ $t('edit') }}</span>
            </div>
            <span class="d-block count">{{ $tc('360.character-count', count) }}</span>
          </div>
        </div>

        <hr class="solid mt-4" />
        <!-- submit -->
        <div class="ac-container--button">
          <button
            :disabled="loading || !text"
            class="btn submit mt-3"
            data-cy="btn-create-message"
            type="submit"
            @click.prevent="submit"
          >
            <span v-if="loading">{{ $tc('_loading-dots', 2) }}</span>
            <span v-else>
              <template v-if="!isEditMode">
                {{ $t('360.add-message') }}
              </template>
              <template v-if="isEditMode">
                {{ $t('360.save-message') }}
              </template>
            </span>
          </button>
        </div>
      </div>

      <!-- right side -->
      <div class="ac-variables">
        <p>{{ $t('360.drag-the-variables-below-into-the-message-field') }}</p>
        <label>{{ $t('360.actions.search-variable') }}</label>
        <div class="form-wrapper">
          <input
            v-model="variable"
            class="input-search"
            :placeholder="$t('360.actions.placeholder.search')"
            @input="filterVariable()"
          />
          <i class="icon icon-search"></i>
        </div>
        <div class="actions-message__labels customScrollBar">
          <ul>
            <!-- Think in use filterVariable as computed -->
            <li
              v-for="label in filterVariable()"
              :key="label"
              draggable="true"
              @dragstart="
                evt => {
                  onDrag(evt, label);
                }
              "
            >
              {{ label }}
            </li>
          </ul>
        </div>
      </div>
    </form>
    <base-loader v-if="loadingModal" />
  </el-dialog>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification';
// helpers
import bus from '@/helpers/events/bus';
// services
import { putTemplate, getTemplateVariables, getMessages, getRenderMessage } from '@/services/actions';
import { getScopeValue } from '@/services/scope';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'ActionsNewMessageModal',
  components: {
    vSelect,
    BaseLoader,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      id: 'actions-new-message-modal',

      templateId: null,

      scope: '',
      scopeValue: '',
      channel: 'whatsapp',
      text: '',
      variable: '',
      textWithVariable: '',

      channelTypes: [
        {
          id: 'whatsapp',
          name: this.$t('360.whatsapp'),
        },
        {
          id: 'sms',
          name: this.$t('360.sms'),
        },
      ],

      labels: [],
      scopeValueList: [],
      scopeList: [
        {
          id: 'brand',
          name: this.$tc('_brand', 1),
        },
        {
          id: 'action',
          name: this.$tc('_action', 1),
        },
        {
          id: 'campaign',
          name: this.$tc('_campaign', 1),
        },
        {
          id: 'store',
          name: this.$tc('_store', 1),
        },
      ],

      loadingScope: false,
      dradding: null,
      isEditMode: false,
      hasError: false,
      loading: false,
      loadingModal: false,
      disableTextarea: false,
      loaderRenderMessage: false,
      viewOtherMessage: false,
      textWillBeSended: '',
      isVisible: false,
    };
  },
  computed: {
    count() {
      return this.text.length;
    },
  },
  watch: {
    isVisible() {
      if (!this.isVisible) this.resetData();
    },
  },
  mounted() {
    this.fetchTemplates();
    bus.$on(`open-${this.id}`, templateId => {
      if (templateId) this.isEditMode = true;

      this.fetchEditData(templateId);
      this.viewOtherMessage = false;
      this.disableTextarea = false;
      this.openModal();
    });

    bus.$on('update-selected-brand', () => this.fetchTemplates());
  },
  beforeUnmount() {
    bus.$off([`open-${this.id}`, 'update-selected-brand']);
  },
  methods: {
    fetchTemplates() {
      getTemplateVariables().then(response => {
        const data = response?.data;
        if (data && data?.success) this.labels = data?.data;
      });
    },

    fetchScopeValue() {
      this.loadingScope = true;
      getScopeValue({ type: this.scope })
        .then(response => {
          const data = response?.data;
          if (data && data?.success) this.scopeValueList = data?.data;
        })
        .finally(() => {
          this.loadingScope = false;
        });
    },

    setDrag(id) {
      this.dradding = id;
    },

    onDrag(evt, variable) {
      evt.dataTransfer.setData('text/plain', variable);
      this.setDrag(variable);
    },

    focusTextarea() {
      this.$refs.textarea.focus();
    },

    changeScope() {
      this.scopeValue = '';

      // if scope is type brand, return
      if (this.checkScopeBrand()) return;

      // fetch scope list
      this.fetchScopeValue();
    },

    /**
     * @description if the scope is type brand, disable the scope input
     */
    checkScopeBrand() {
      return this.scope === 'brand';
    },

    fetchEditData(templateId) {
      if (!templateId) return;

      this.loadingModal = true;

      this.templateId = templateId;

      getMessages({ templateId })
        .then(({ data }) => {
          const { name, scope, url, channel, text } = data?.data[0];

          this.name = name;
          this.scope = scope?.key;
          this.scopeValue = scope?.name;
          this.url = url;
          this.channel = channel;
          this.text = text;
        })
        .finally(() => {
          this.loadingModal = false;
        });
    },

    submit() {
      const templateId = this.templateId || '';
      const scope = this.scope;
      const scopeValue = this.scopeValue?.id;
      const channel = this.channel;
      const text = this.textWillBeSended ? this.textWillBeSended : this.text;

      this.loading = true;

      putTemplate({ templateId, scope, scopeValue, channel, text })
        .then(data => {
          if (!data.data.success) return bus.$emit('open-actions-dialog-error-modal');

          bus.$emit('actions-messages-refresh-list');
          const toastMsg = this.isEditMode ? '360.actions.toast.msg-update' : '360.actions.toast.msg-success';
          this.toast.success(this.$t(`${toastMsg}`), {
            timeout: 5000,
          });
        })
        .catch(() => {
          bus.$emit('open-actions-dialog-error-modal');
        })
        .finally(() => {
          this.closeModal();
          this.loading = false;
        });
    },

    resetData() {
      this.templateId = null;
      this.isEditMode = false;

      this.scope = '';
      this.scopeValue = '';
      this.channel = 'whatsapp';
      this.text = '';
    },

    fetchRenderMessage() {
      this.loaderRenderMessage = true;
      if (!this.viewOtherMessage || !this.disableTextarea) {
        this.textWithVariable = this.text;
        this.textWillBeSended = this.textWithVariable;
      } else {
        this.textWillBeSended = this.textWithVariable;
      }
      this.viewOtherMessage = true;
      getRenderMessage(this.textWillBeSended)
        .then(response => {
          const data = response?.data;
          if (!data?.data) return;
          this.text = data?.data?.message;
          this.disableTextarea = true;
        })
        .finally(() => {
          this.loaderRenderMessage = false;
        });
    },
    filterVariable() {
      return this.labels.filter(item => item.includes(this.variable));
    },
    enableTextarea() {
      this.disableTextarea = false;
      this.text = this.textWillBeSended;
      this.textWillBeSended = '';
    },

    /**
     * @description modal events
     */
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
#actions-new-message-modal {
  @import '@/assets/scss/vendors/_v-select';

  width: 800px;
  height: 570px;

  .el-dialog__header {
    position: absolute;
    z-index: 1;
    right: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .customScrollBar {
    &::-webkit-scrollbar-thumb {
      background: $gray-500;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $gray-500;
    }
    &::-webkit-scrollbar-thumb:active {
      background: $gray-500;
    }
  }

  .actions-message {
    height: 540px;
    display: flex;

    .ac-form {
      width: 65%;
      padding: 40px 48px 32px 40px;
    }

    .ac-variables {
      width: 35%;
      padding: 78px 32px 40px 24px;
      background-color: $gray-200;
      border-radius: 0 10px 10px 0;
    }

    .actions-message__labels {
      height: 335px;
      max-width: 232px;
      overflow: auto;
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        li {
          margin: 2px;
          border-radius: 5px;
          background-color: $oto-omni;
          color: $color-white;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 16px;
          padding: 5px;
          cursor: move;
        }
      }
    }
    .drag-drop {
      position: relative;
      margin-top: 20px;

      textarea {
        width: 100%;
        min-height: 126px;
        border: 1px solid $gray-500;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 20px 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: $gray-600;
      }

      textarea:disabled {
        background-color: $gray-200;
      }

      .view-edit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 59px;

        div {
          span:nth-child(1) {
            margin-right: 15px;
          }
          span {
            font-size: 12px;
            color: $blue-500;
            cursor: pointer;
          }
        }
      }

      .count {
        color: $gray-600;
        cursor: default;
        font-size: 12px;
      }
    }

    .ac-container--button {
      button.submit {
        padding: 0 30px;
        width: 400px;
        margin: 0 auto;
      }
    }

    hr {
      margin-left: -50px;
      width: calc(100% + 100px);
    }
    .form-wrapper {
      position: relative;
      z-index: 1;
      margin-bottom: 18px;

      .input-search {
        width: 100%;
        background-color: transparent;
        height: 36px;
      }
      .icon-search {
        color: $gray-500;
        position: absolute;
        top: 3px;
        right: 8px;
      }
    }
  }
  .dd__loader {
    position: absolute;
    width: 30px;
    right: 189px;
    top: 70px;
    height: 35px;
  }
}
</style>
