import moment from 'moment';

export default {
  name: 'en',
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Clear',
      defaultLabel: 'color picker',
      description: 'current color is {color}. press enter to select a new color.',
    },
    datepicker: {
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      dateTablePrompt: 'Use the arrow keys and enter to select the day of the month',
      monthTablePrompt: 'Use the arrow keys and enter to select the month',
      yearTablePrompt: 'Use the arrow keys and enter to select the year',
      selectedDate: 'Selected date',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      year: '',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      week: 'week',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
      },
      weeksFull: {
        sun: 'Sunday',
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec',
      },
    },
    shortcuts: [
      {
        text: 'Yesterday',
        value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        key: 'yesterday',
      },
      {
        text: 'Last 7 days',
        value: [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
        key: 'lastSevenDays',
      },
      {
        text: 'Last 30 days',
        value: [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
        key: 'lastThirtyDays',
      },
      {
        text: 'This month',
        value: [moment().subtract(1, 'days').startOf('month'), moment().subtract(1, 'days').endOf('month')],
        key: 'thisMonth',
      },
      {
        text: 'Last month',
        value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        key: 'lastMonth',
      },
      {
        text: 'Last 6 months',
        value: [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        key: 'lastSixMonths',
      },
      {
        text: 'This year',
        value: [moment().startOf('year'), moment().subtract(1, 'days')],
        key: 'thisYear',
      },
      {
        text: 'Last year',
        value: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        key: 'lastYear',
      },
      {
        text: '12 months + current',
        value: [moment().subtract(12, 'months').startOf('month'), moment().subtract(1, 'days')],
        key: 'twelveMonthsPlusCurrent',
      },
    ],
  },
};
