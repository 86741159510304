import { EditTableResponse, TableMetricsData, ErrorType } from '../models';
import { getTableMetrics } from '../services';

interface EditColumnsData {
  success: boolean;
  errors: ErrorType[];
  table_metrics: {
    key: TableMetricsData[];
  };
  ordering: TableMetricsData[];
  warnings: object[];
}

export const fetchEditColumnsData = (preset: string): Promise<EditTableResponse> =>
  getTableMetrics(preset).then(response => formatResponse(response?.data));

const formatResponse = (response: EditColumnsData): EditTableResponse => ({
  success: response?.success,
  errors: response?.errors,
  tableMetrics: Object.keys(response?.table_metrics || {}).map(metricKey => ({
    key: metricKey,
    data: [...response?.table_metrics[metricKey]].map(item => ({
      key: item?.key,
      name: item?.name,
      type: item?.type,
      preview: item?.preview,
    })),
  })),
  ordering: response?.ordering,
  warnings: response?.warnings,
});
