<template>
  <div class="users-list">
    <header class="users-list__header">
      <h1 class="card-group-title">{{ $t('settings.users.title') }}</h1>
      <button
        v-if="loginWithPin"
        class="btn users-list__btn-create-user"
        @click.prevent="$parent.openCreate"
        data-cy="btn-user-create"
      >
        {{ $t('settings.users.new-user') }}
      </button>
    </header>

    <table-list :data="data" :type="type">
      <table-list-header v-if="$_verifyLoaded('done')">
        <table-list-column :class-name="loginWithPin ? 'col-4' : 'col-6'" data-cy="users-table-list-header-name">{{
          $t('settings.users.name')
        }}</table-list-column>
        <table-list-column :class-name="loginWithPin ? 'col-4' : 'col-6'" data-cy="users-table-list-header-marcas">{{
          $tc('brands', 0)
        }}</table-list-column>
        <table-list-column v-if="loginWithPin" class-name="col-3" data-cy="users-table-list-header-perfil"
          >{{ $t('settings.users.profile') }}
        </table-list-column>
        <table-list-column v-if="loginWithPin" class-name="text-right col-1"></table-list-column>
      </table-list-header>

      <table-list-body class="users-list__table-body" :class="{ loadHeight: loading || hasError || isEmpty }">
        <template v-if="$_verifyLoaded('done')">
          <table-list-row
            v-for="(user, index) in users"
            :key="index"
            :class="{ disabled: !user.enabled }"
            data-cy="users-table-list"
          >
            <table-list-column :class-name="loginWithPin ? 'users-list__username col-4' : 'users-list__username col-6'">
              <span :ref="`username-${user.id}`" class="users-list__name d-block" data-cy="user-list-name">
                {{ user.name }}
              </span>
              <span class="email">{{ user.email }}</span>
            </table-list-column>

            <table-list-column :class-name="loginWithPin ? 'users-list__brands col-4' : 'users-list__brands col-6'">
              <span v-for="(brand, index) in user.brands" :key="index">
                {{ brand }}<span v-if="index !== user.brands.length - 1">, </span>
              </span>
            </table-list-column>

            <table-list-column v-if="loginWithPin" class-name="col-3"
              >{{ user.profile.name }} ({{ user.client.name }})</table-list-column
            >

            <table-list-column v-if="loginWithPin" class-name="text-right col-1">
              <div class="dropdown" data-cy="users-table-list-dropdown">
                <a
                  id="dropdownusers"
                  href="#"
                  class="dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="icon icon-menu-14px"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownuser" data-cy="users-table-list-dropdown-options">
                  <a
                    :class="['dropdown-item', { disabled: filterDuplicatedAccounts() }]"
                    @click.prevent="$parent.openEdit(user)"
                    data-cy="users-table-list-dropdown-edit"
                    >{{ $t('edit') }}</a
                  >
                  <a class="dropdown-item" @click.prevent="disableUser(user)">{{
                    user.enabled == 1 ? $t('settings.disable') : $t('settings.enable')
                  }}</a>
                  <a
                    class="dropdown-item"
                    @click.prevent="triggerConfirmModal(user)"
                    data-cy="users-table-list-dropdown-reset-password"
                  >
                    {{ $t('settings.users.reset-password') }}
                  </a>
                  <a v-if="user.is_locked" class="dropdown-item" @click.prevent="unlockUserModal(user)">
                    {{ $t('settings.users.unlock') }}
                  </a>
                </div>
              </div>
            </table-list-column>
          </table-list-row>
        </template>
        <base-loader v-show="$_verifyLoaded('loading')" />
        <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
      </table-list-body>
    </table-list>
    <user-question-modal :loading="loadingReset" :error="errorReset" />
    <user-dialog-error-modal />
    <user-unlock-modal />
  </div>
</template>

<script>
// libs
import { useToast } from 'vue-toastification';
// services
import { list, disable, postResetPassword } from '@/services/userSettings';
// helpers
import bus from '@/helpers/events/bus';
import verifyMixin from '@/helpers/mixins/verifyMixin';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import UserQuestionModal from '@/components/settings/users/UserQuestionModal';
import UserDialogErrorModal from '@/components/settings/users/UserDialogErrorModal';
import UserUnlockModal from '@/components/settings/users/UserUnlockModal';
// store
import store from '@/store';

export default {
  name: 'UsersList',
  components: {
    BaseLoader,
    InfoCard,
    UserQuestionModal,
    UserDialogErrorModal,
    UserUnlockModal,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  mixins: [verifyMixin],
  data() {
    return {
      users: [],
      type: 'filled',
      data: [],
      selectedUser: {},
      loadingReset: false,
      errorReset: false,
    };
  },
  created() {
    this.getUsers();
  },
  mounted() {
    bus.$on('update-users', mode => {
      this.getUsers();
      if (mode) {
        this.toast.success(this.$t(`settings.users.${mode}.toast-success`), { timeout: 5000 });
      } else {
        this.toast.success(this.$t(`settings.users.default-toast-success`), { timeout: 5000 });
      }
    });
    bus.$on('user-reset-password', () => {
      this.resetPassword();
    });
  },
  beforeUnmount() {
    bus.$off('user-reset-password');
  },
  computed: {
    loginWithPin() {
      return !store.getters.hasSso;
    },
  },
  methods: {
    getUsers() {
      this.$_reqConfig();
      list()
        .then(response => {
          if (this.$_verifyData(response?.data)) return;
          this.formatUsers(response?.data);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatUsers(data) {
      this.users = [];
      for (let i = 0; i < data.length; i += 1) {
        for (let x = 0; x < data[i].users.length; x += 1) {
          const brands = [];
          data[i].users[x].accounts.forEach(brand => {
            brands.push(brand.account);
          });
          this.users.push({
            id: data[i]?.users[x]?.id,
            name: data[i]?.users[x]?.name,
            email: data[i]?.users[x]?.login,
            client: {
              name: data[i]?.users[x].accounts[0].account,
              clientId: data[i]?.client_id,
              id: data[i]?.users[x].accounts[0]?.id,
              hasMultipleAccounts: data[i]?.users[x].accounts.length > 1,
            },
            brands,
            profile: {
              name: data[i].users[x]?.access?.profile
                ? data[i].users[x]?.access?.profile
                : data[i].users[x]?.groups?.group,
              id: data[i].users[x]?.access?.id ? data[i].users[x]?.access?.id : data[i].users[x]?.groups?.id,
              description: data[i]?.users[x]?.access?.description ? data[i]?.users[x]?.access?.description : '',
            },
            enabled: data[i]?.users[x]?.enabled,
            is_locked: data[i]?.users[x]?.is_locked,
            user_360: data[i]?.users[x]?.user_360,
          });
          this.order(this.users);
        }
      }
    },
    disableUser(user) {
      disable(user.id, user.enabled === 0 ? 1 : 0).then(response => {
        if (response?.data?.success) {
          bus.$emit('update-users');
        } else {
          this.toast.error(this.$t('settings.users.edit.error'), { timeout: 10000 });
        }
      });
    },
    order(array) {
      return array.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    filterDuplicatedAccounts(user) {
      if (!user) return;
      return new Set(user.map(v => v.accounts)).size < user.length;
    },
    triggerConfirmModal(user) {
      this.selectedUser = user;
      bus.$emit('openUserQuestionModal');
      this.errorReset = false;
    },
    resetPassword() {
      if (!this.selectedUser?.id) return;
      this.loadingReset = true;
      postResetPassword(this.selectedUser?.id)
        .then(({ data }) => {
          if (data.success) this.toast.success(this.$t('settings.users.reset-success'));
        })
        .catch(() => {
          this.errorReset = true;
        })
        .finally(() => {
          this.loadingReset = false;
        });
    },
    unlockUserModal(user) {
      bus.$emit('openUserUnlockModal', user);
    },
  },
};
</script>

<style lang="scss" scoped>
.users-list {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__btn-create-user {
    padding: 0 50px;
    margin: 30px 0;
  }

  &__table {
    &-body {
      &.loadHeight {
        min-height: 400px;
      }
    }
  }

  &__name {
    font-weight: 600;
  }

  &__brands {
    padding-right: 30px;
  }
}

.table-list-row {
  &.disabled {
    .table-list-column {
      color: $gray-600;
    }
  }
}
.dropdown-item {
  &.disabled {
    display: none;
  }
}
</style>

<style lang="scss">
#users {
  .table-list-body.filled {
    overflow: initial;
  }
  .table-list-row {
    &:last-child {
      .card {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
</style>
