<template>
  <div id="users">
    <users-list />
    <user-create-edit :user-selected="userSelected" :mode="mode" :reset-fields="clearUserSelected" />
  </div>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';
// components
import UsersList from '@/components/settings/users/UsersList';
import UserCreateEdit from '@/components/settings/users/UserCreateEdit';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'Users',
  components: {
    UsersList,
    UserCreateEdit,
  },
  data() {
    return {
      userSelected: {
        id: '',
        name: '',
        email: '',
        client: null,
        brands: [],
        profile: null,
        enabled: '',
        routes: '',
        user_360: null,
      },
      mode: null,
    };
  },
  mounted() {
    useMixpanelConfig('view_settings_users');
  },
  methods: {
    openCreate() {
      this.mode = 'create';
      this.$router.push('/settings/users/create');
      bus.$emit('user-create-open');
    },
    openEdit(user) {
      this.mode = 'edit';
      this.userSelected = {
        id: user?.id,
        name: user?.name,
        email: user?.email,
        client: {
          clientId: user.client?.clientId,
          name: user.client?.name,
          id: user.client?.id,
          stores: user.client?.stores,
          hasMultipleAccounts: user.client?.hasMultipleAccounts,
        },
        brands: user.brands.map(brand => brand),
        profile: {
          id: user.profile?.id,
          name: user.profile?.name,
          description: user.profile?.description,
        },
        enabled: user?.enabled,
        routes: user?.routes,
        user_360: user?.user_360,
        dataComplete: false,
      };
      this.$router.push(`/settings/users/edit/${user.id}`);
      bus.$emit('user-edit-open');
    },
    clearUserSelected() {
      this.userSelected = {
        id: '',
        name: '',
        email: '',
        client: null,
        brands: [],
        profile: null,
        enabled: '',
        routes: '',
        user_360: null,
      };
    },
  },
};
</script>
