export type ErrorType = {
  code: string;
  message: string;
};

export type TableMetricsData = {
  key: string;
  name: string;
  type: string;
  preview: boolean;
};

export type EditTableResponse = {
  success: boolean;
  errors: ErrorType[];
  tableMetrics: FormattedData[];
  ordering: TableMetricsData[];
  warnings: object[];
};

export type DataType = {
  key: string;
  name: string;
};

export type FormattedData = {
  key: string;
  data: TableMetricsData[];
};

export type PostEditColumnsResponse = {
  data: {
    ConfigID: number;
  };
  errors: ErrorType[];
  success: boolean;
};

export type PostEditColumnsPreset = 'campaign' | 'date' | 'sales' | 'seller' | 'store' | 'store_place';

export enum SelectedPreset {
  CAMPAIGN = 'campaign',
  DATE = 'date',
  SALES = 'sales',
  STORE = 'store',
  STORE_PLACE = 'store_place',
  SELLER = 'seller',
}
