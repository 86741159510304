import { createStore } from 'vuex';

import auth from '@/store/modules/auth';
import actionAttachments from '@/store/modules/actionAttachments';
import actionMessages from '@/store/modules/actionMessages';
import audience from '@/store/modules/audience';
import base from '@/store/modules/base';
import cashback from '@/store/modules/cashback';
import cashbackReport from '@/store/modules/cashbackReport';
import dashboard from '@/store/modules/dashboard';
import historyExport from '@/store/modules/historyExport';
import historyImport from '@/store/modules/historyImport';
import indicators from '@/store/modules/indicators';
import labels from '@/store/modules/labels';
import npsAnalytics from '@/store/modules/npsAnalytics';
import npsCampaigns from '@/store/modules/npsCampaigns';
import personas from '@/store/modules/personas';
import pushNotifications from '@/store/modules/pushNotifications';
import sellers from '@/store/modules/sellers';
import salesReport from '@/store/modules/salesReport';
import campaignsManagement from '@/store/modules/campaignsManagement';
import settingsStores from '@/store/modules/settingsStores';
import customFields from './store/modules/customFields';
import rawDataAccess from '@/store/modules/rawDataAccess';
import engagementReport from '@/store/modules/engagementReport';
import salesReportNew from '@/store/modules/salesReportNew';

export default createStore({
  modules: {
    actionAttachments,
    actionMessages,
    audience,
    auth, // namespace global
    base,
    cashback,
    cashbackReport,
    dashboard,
    historyExport,
    historyImport,
    indicators,
    labels,
    npsAnalytics,
    npsCampaigns,
    personas,
    pushNotifications,
    sellers,
    salesReport,
    campaignsManagement,
    settingsStores,
    customFields,
    rawDataAccess,
    engagementReport,
    salesReportNew,
  },
});
