<template>
  <div class="action-filters">
    <!-- channel -->
    <div class="a-filters__item">
      <label for="status">{{ $t('360.actions.channel') }}</label>
      <v-select
        :clearable="false"
        :searchable="false"
        :options="channelList"
        label="name"
        :placeholder="$tc('_select', 2)"
        @option:selected="onChangeChannel"
      >
        <template #option="{ name }">
          {{ $tc(name, 2) }}
        </template>
        <template #selected-option="{ name }">
          {{ $tc(name, 2) }}
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('integrations.no-results') }}
        </template>
      </v-select>
    </div>
    <!-- scopeType -->
    <div class="a-filters__item">
      <label>{{ $t('360.actions.scope') }}</label>
      <v-select
        :clearable="false"
        :searchable="false"
        :options="scopeTypeList"
        :placeholder="$tc('_select', 2)"
        label="name"
        @option:selected="onChangeScopeType"
      >
        <template #option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #selected-option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('no-results') }}
        </template>
      </v-select>
    </div>
    <!-- campaign -->
    <div class="a-filters__item">
      <label>{{ $tc('_value', 1) }}</label>
      <v-select
        v-model="scope"
        :clearable="false"
        :options="getScopeList"
        :placeholder="$tc('_select', 2)"
        :disabled="checkScopeTypeBrand"
        label="name"
        @option:selected="onChangeScopeValue"
      >
        <template #option="{ name }">
          <span :title="$tc(name, 1)">{{ $tc(name, 1) }}</span>
        </template>
        <template #selected-option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('no-results') }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
// libs, helpers
import vSelect from 'vue-select';
import { mapState, mapActions, mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';

export default {
  name: 'ActionMessageHeaderFilters',
  components: {
    vSelect,
  },
  data() {
    return {
      scope: '',
    };
  },
  computed: {
    ...mapState('actionMessages', ['channelList', 'scopeTypeList']),
    ...mapGetters('actionMessages', ['getScopeType', 'getScopeList']),

    /**
     * @description if the scopeType is type brand, disable the scope input
     */
    checkScopeTypeBrand() {
      return this.getScopeType === 'brand';
    },
  },
  beforeUnmount() {
    bus.$off(['update-selected-brand', 'render-cards']);
  },
  methods: {
    ...mapActions('actionMessages', ['setChannel', 'setScopeType', 'setScopeValue']),

    onChangeChannel($event) {
      this.setChannel($event.id);
    },

    onChangeScopeType($event) {
      // if scopeType is type brand, return
      this.setScopeType($event.id);
      this.scope = '';
    },

    onChangeScopeValue($event) {
      this.setScopeValue($event.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-filters {
  display: flex;
  justify-content: end;
  gap: 30px;
  margin: auto 30px auto auto;
  width: 840px;

  .a-filters__item {
    display: flex;
    flex-direction: column;
    width: 190px;

    select {
      height: 36px;
    }

    &--export {
      display: flex;
      align-items: flex-end;
    }
  }
}
</style>

<style lang="scss">
.a-filters__container {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
