// import { computed } from 'vue';

require('dayjs/locale/pt-br');
require('dayjs/locale/en');

import ptBR from '@/locales/datepicker/pt-BR';
import en from '@/locales/datepicker/en';
import dayjs from 'dayjs';

import bus from '@/helpers/events/bus';
import store from '@/store';

const formatLanguage = (langauge: string) => {
  if (!langauge) return;
  return dayjs().locale(langauge).format();
};

export const datepickerLanguage = (language: string) => {
  const locale = language === 'pt-BR' ? ptBR : en;
  formatLanguage(language);
  return locale;
};

export const datepickerUpdate = (newDates: string[]) => {
  const dates = {
    startDate: newDates[0],
    endDate: newDates[1],
  };

  const initialDay = dayjs(dates.startDate);
  const finalDay = dayjs(dates.endDate);
  const numberOfDays = finalDay.diff(initialDay, 'day');
  // const selectedSegment = computed<string>(() => store.getters['engagementReport/getSelectedSegment']);

  store.dispatch('changeDates', dates);
  // store.dispatch('engagementReport/setFetchSegmentOptions', selectedSegment.value);
  // store.dispatch('engagementReport/setResetFilter');

  bus.$emit('render-cards', numberOfDays);
};
