import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createSlots as _createSlots, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "sales-report-new-filters-selected-segment" }
const _hoisted_2 = ["title", "onChange"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "loading" }
const _hoisted_5 = {
  key: 0,
  class: "icon icon-search"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_base_loader = _resolveComponent("base-loader")!
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.$t(`360.sales-report-new.select-${_ctx.getSelectedSegment}`)), 1),
    _withDirectives((_openBlock(), _createBlock(_component_el_select_v2, {
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputValue) = $event)),
      options: _ctx.optionsList,
      clearable: "",
      filterable: "",
      loading: _ctx.loadingSelect,
      placement: "bottom",
      placeholder: ' ',
      "popper-class": "sales-report-new-filters",
      multiple: "",
      style: {"width":"243px"},
      "item-height": 28,
      onClear: _ctx.clearAllOptions,
      onVisibleChange: _ctx.updateData,
      "filter-method": _ctx.filterMethod
    }, _createSlots({
      default: _withCtx(({ item }) => [
        _createElementVNode("div", {
          title: item.label,
          onChange: ($event: any) => (_ctx.updateCheckedItem(item))
        }, [
          _createVNode(_component_el_checkbox, {
            modelValue: item.isChecked,
            "onUpdate:modelValue": ($event: any) => ((item.isChecked) = $event)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue"])
        ], 40, _hoisted_2)
      ]),
      tag: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass({ opacity: _ctx.isDropdownOpened })
        }, _toDisplayString(_ctx.selectedItemsValue), 3)
      ]),
      loading: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_base_loader, { height: "20" })
        ])
      ]),
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('360.sellers.no-results')), 1)
      ]),
      prefix: _withCtx(() => [
        (_ctx.isDropdownOpened)
          ? (_openBlock(), _createElementBlock("i", _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (!_ctx.loadingSelect)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _createVNode(_component_el_checkbox, {
                modelValue: _ctx.allChecked,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.allChecked) = $event)),
                onChange: _ctx.selectAllOptions,
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tc(_ctx.allSelectedText, 2)), 1)
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ]),
            key: "0"
          }
        : undefined,
      (!_ctx.loadingSelect)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("button", {
                class: "btn",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.applyFilters())),
                disabled: _ctx.disabledButton
              }, _toDisplayString(_ctx.$t(`360.sales-report-new.apply-filter`)), 9, _hoisted_3)
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["modelValue", "options", "loading", "onClear", "onVisibleChange", "filter-method"])), [
      [_directive_click_outside, _ctx.resetFilters]
    ])
  ]))
}