<template>
  <div class="audiences-stats-skeleton">
    <div class="container">
      <div class="cols d-flex align-items-center justify-content-between">
        <div>
          <skeleton-loader height="19px" width="240px" class="mb-12 d-block" />
          <skeleton-loader height="19px" width="110px" />
        </div>
        <div class="d-flex">
          <skeleton-loader height="33px" width="40px" class="mr-12" />
          <skeleton-loader height="33px" width="40px" class="mr-12" />
        </div>
        <div class="d-flex">
          <skeleton-loader width="180px" class="mr-12" />
          <skeleton-loader width="180px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
<style lang="scss" scoped>
.audiences-stats-skeleton {
  padding: 15px 0 25px;
  background-color: $color-white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  color: $color-white;
  z-index: 98;

  .cols {
    max-width: 99%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md-max-width) {
      flex-wrap: wrap;
    }
  }
  .mr-12 {
    margin-right: 12px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
}
</style>
