// libs
import i18n from '@/i18n';
import Axios from 'axios';
import router from '@/router';
import moment from 'moment';
// helpers
import * as h from '@/helpers/auth';
import bus from '@/helpers/events/bus';
import { menuItems } from '@/helpers/menu';
// services
import { postLogin, postFederatedLogin, postForgotLogin } from '@/services/auth';
import { updateLanguage } from '@/services/userSettings';
import { getRoutes } from '@/services/routes';
import { acceptTos, getUserBrands, getAccessLevel, getBrandStores, getBrandOptions } from '@/services/users';
// constants
import { accessType } from '@/utilities/constants';

export const defaultAuthState = {
  attributionInterval: h.getFromStorage('attributionInterval')
    ? parseFloat(h.getFromStorage('attributionInterval'))
    : null,
  attributionType: h.getFromStorage('attributionType') ? h.getFromStorage('attributionType') : null,
  brandDefaultRoute: h.getFromStorage('brandDefaultRoute') || '',
  brandRoutes: h.getFromStorage('brandRoutes') ? JSON.parse(h.getFromStorage('brandRoutes')) : null,
  brands: h.getFromStorage('brands') ? JSON.parse(h.getFromStorage('brands')) : null,
  dates: h.datesLocalStorage || h.datesMoment,
  email: null,
  expInvalidCellphone: h.getFromStorage('expInvalidCellphone') ? +h.getFromStorage('expInvalidCellphone') : null,
  expInvalidEmail: h.getFromStorage('expInvalidEmail') ? +h.getFromStorage('expInvalidEmail') : null,
  expInvalidPhone: h.getFromStorage('expInvalidPhone') ? +h.getFromStorage('expInvalidPhone') : null,
  lastEmail: null,
  ready: h.getFromStorage('ready') ? +h.getFromStorage('ready') : null,
  recency: h.getFromStorage('recency') ? h.getFromStorage('recency') : null,
  generalSuggestionsLimit: h.getFromStorage('generalSuggestionsLimit')
    ? h.getFromStorage('generalSuggestionsLimit')
    : null,
  quarantinePeriod: h.getFromStorage('quarantinePeriod') ? h.getFromStorage('quarantinePeriod') : null,
  personas: h.getFromStorage('personas') ? JSON.parse(h.getFromStorage('personas')) : null,
  segments: h.getFromStorage('segments') ? JSON.parse(h.getFromStorage('segments')) : null,
  segmentsColors: h.getFromStorage('segmentsColors') ? JSON.parse(h.getFromStorage('segmentsColors')) : null,
  selectedBrand: h.getFromStorage('selectedBrand') ? JSON.parse(h.getFromStorage('selectedBrand')) : null,
  selectedPersona: h.getFromStorage('selectedPersona') ? JSON.parse(h.getFromStorage('selectedPersona')) : null,
  selectedFilter: h.getFromStorage('selectedFilter') ? JSON.parse(h.getFromStorage('selectedFilter')) : null,
  selectedSegment: h.getFromStorage('selectedSegment')
    ? JSON.parse(h.getFromStorage('selectedSegment'))
    : h.selectedSegmentDefault,
  token: h.getFromStorage('token') || null,
  tokenV3: h.getFromStorage('token-v3') || null,
  termsOfService: h.getFromStorage('user') ? JSON.parse(h.getFromStorage('user')).termsOfService : null,
  user: h.getFromStorage('user') ? JSON.parse(h.getFromStorage('user')) : null,
  accessLevel: h.getFromStorage('accessLevel') ? JSON.parse(h.getFromStorage('accessLevel')) : null,
  featureFlags: h.getFromStorage('featureFlags') ? JSON.parse(h.getFromStorage('featureFlags')) : null,
};

const auth = {
  state: defaultAuthState,
  mutations: {
    LOGIN(state) {
      state.pending = true;
    },
    LOGIN_SUCCESS(state, data, currency) {
      state.pending = false;
      state.token = data.public_token;
      state.tokenV3 = data.new?.access_token || data.access_token;

      if (Axios && Axios.defaults && Axios.defaults.headers && Axios.defaults.headers.common) {
        Axios.defaults.headers.common.Authorization = `Bearer ${data.public_token}`;
      }

      state.user = {};
      state.user.accessType = data.accessType;
      state.user.language = data.user_language;
      state.user.name = data.user_name;
      state.user.email = data.user_email;
      state.user.lastEmail = data.user_email;
      state.user.id = data.user_id;
      state.user.profile = data.user_type;
      state.user.termsOfService = data.tos;
      state.currency = currency;
      state.ready = 1;

      if (data.federation) {
        state.user.federation = data.federation;
      }

      h.setStorage('token', data.public_token);
      h.setStorage('token-v3', state.tokenV3);
      h.setStorage('user', JSON.stringify(state.user));
      h.setStorage('uuid', data.uuid);
      h.setStorage('ready', state.ready);
    },
    LOGOUT(state) {
      state.token = null;
      state.tokenV3 = null;
      state.email = null;
      state.brands = null;
      state.user = null;
      state.selectedBrand = null;
      state.dates = h.datesMoment;
      state.ready = null;
      state.selectedPersona = null;
      state.selectedFilter = null;
      state.selectedSegment = h.selectedSegmentDefault;
      state.attributionInterval = null;
      state.attributionType = null;
      state.recency = null;
      state.generalSuggestionsLimit = null;
      state.quarantinePeriod = null;
      state.personas = null;
      state.segments = null;
      state.segmentsColors = null;
      state.brandRoutes = null;
      state.brandDefaultRoute = null;
      state.termsOfService = null;
      state.currency = null;
      state.accessLevel = null;
      state.featureFlags = null;

      if (Axios && Axios.defaults && Axios.defaults.headers && Axios.defaults.headers.common) {
        Axios.defaults.headers.common.Authorization = null;
      }

      bus.$emit('update-experimental-mode', false);
      bus.$emit('dismiss-toasts');

      const { uuid } = localStorage;
      localStorage.clear();
      if (uuid) localStorage.uuid = uuid;

      router.push('/login');
    },
    EMAIL(state, email) {
      state.email = email;
      state.lastEmail = email;
      const saveEmail = state.email;
      return saveEmail;
    },
    TOKEN(state, token) {
      state.token = token;
      h.setStorage('token', token);

      if (Axios && Axios.defaults && Axios.defaults.headers && Axios.defaults.headers.common) {
        Axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null;
      }
    },
    DATES(state, dates) {
      JSON.parse(JSON.stringify(dates));
      state.dates.startDate = dates.startDate;
      state.dates.endDate = dates.endDate;
      h.setStorage('dates', JSON.stringify(dates));
    },
    LANGUAGE(state, language) {
      state.user.language = language;
      updateLanguage(language);
      h.setStorage('user', JSON.stringify(state.user));
    },
    SELECT_BRAND(state, brand) {
      if (!brand) return h.setStorage('selectedBrand', null);

      state.selectedBrand = brand;
      [('stores', 'rfv_groups')].forEach(key => delete state.selectedBrand[key]);
      h.setStorage('selectedBrand', JSON.stringify(brand));
    },
    SELECT_BRAND_ENDPOINT(state, endpoint) {
      state.selectedBrand = {
        ...state.selectedBrand,
        endpoint,
      };
    },
    SELECT_PERSONA(state, persona) {
      state.selectedPersona = persona;
      h.setStorage('selectedPersona', JSON.stringify(persona));
    },
    SELECT_FILTER(state, filter) {
      state.selectedFilter = filter;
      h.setStorage('selectedFilter', JSON.stringify(filter));
    },
    SELECT_SEGMENT(state, segment) {
      state.selectedSegment = segment;
      h.setStorage('selectedSegment', JSON.stringify(segment));
    },
    SELECT_ATTRIBUTION_TYPE(state, type) {
      state.attributionType = type;
      h.setStorage('attributionType', type);
    },
    SELECT_ATTRIBUTION_INTERVAL(state, interval) {
      state.attributionInterval = interval;
      h.setStorage('attributionInterval', interval);
    },
    UPDATE_EXP_INVALID_EMAIL(state, expInvalidEmail) {
      state.expInvalidEmail = expInvalidEmail;
      h.setStorage('expInvalidEmail', expInvalidEmail);
    },
    UPDATE_EXP_INVALID_CELLPHONE(state, expInvalidCellphone) {
      state.expInvalidCellphone = expInvalidCellphone;
      h.setStorage('expInvalidCellphone', expInvalidCellphone);
    },
    UPDATE_EXP_INVALID_PHONE(state, expInvalidPhone) {
      state.expInvalidPhone = expInvalidPhone;
      h.setStorage('expInvalidPhone', expInvalidPhone);
    },
    UPDATE_RECENCY(state, recency) {
      state.recency = recency;
      h.setStorage('recency', recency);
    },
    UPDATE_GENERAL_SUGGESTIONS_LIMIT(state, generalSuggestionsLimit) {
      state.generalSuggestionsLimit = generalSuggestionsLimit;
      h.setStorage('generalSuggestionsLimit', JSON.stringify(generalSuggestionsLimit));
    },
    UPDATE_QUARANTINE_PERIOD(state, quarantinePeriod) {
      state.quarantinePeriod = quarantinePeriod;
      h.setStorage('quarantinePeriod', JSON.stringify(quarantinePeriod));
    },
    UPDATE_PERSONAS(state, personas) {
      state.personas = personas;
      h.setStorage('personas', JSON.stringify(personas));
    },
    UPDATE_SEGMENTS(state, segments) {
      state.segments = segments;
      h.setStorage('segments', JSON.stringify(segments));
    },
    UPDATE_SEGMENTS_COLORS(state, colors) {
      state.segmentsColors = colors;
      h.setStorage('segmentsColors', JSON.stringify(colors));
    },
    UPDATE_TERMS_OF_SERVICE(state, status) {
      state.termsOfService = status;
    },
    UPDATE_DEFAULT_ROUTE(state, payload) {
      const defaultRoute = payload ? payload : '';
      state.brandDefaultRoute = defaultRoute;
      h.setStorage('brandDefaultRoute', defaultRoute);
    },
    UPDATE_ROUTES(state, payload) {
      state.brandRoutes = payload;
      h.setStorage('brandRoutes', JSON.stringify(payload));
    },
    UPDATE_BRANDS(state, brands) {
      state.brands = brands;
      h.setStorage('brands', JSON.stringify(state.brands));
    },
    SET_ACCESS(state, accessLevel) {
      state.accessLevel = accessLevel;
      h.setStorage('accessLevel', JSON.stringify(state.accessLevel));
    },
    SET_FEATURE_FLAGS(state, featureFlags) {
      state.featureFlags = featureFlags;
      h.setStorage('featureFlags', JSON.stringify(state.featureFlags));
    },
  },
  actions: {
    async federatedLogin({ commit, dispatch, getters }, { code }) {
      commit('LOGIN');

      const uuid = h.getFromStorage('uuid');
      const isAccountApp = i18n.global.t('login.permission-error');
      const brandsError = i18n.global.t('login.brands-error');

      try {
        const login = await postFederatedLogin({ code, uuid });
        if (!login) return bus.$emit('trigger-global-error');

        commit('LOGIN_SUCCESS', login.data, getters.currency);
        if (getters.isAccessLevelApp) return dispatch('setFailedLogin', isAccountApp);

        h.onChangeLocale(login.data.user_language, getters.currency);

        await dispatch('setUserBrands');
        const { id, endpoint } = getters?.brands?.[0];
        commit('SELECT_BRAND_ENDPOINT', endpoint);
        await Promise.all([dispatch('setBrandStores', id), dispatch('setBrandOptions', id)]);

        if (!getters.brands.length) return dispatch('setFailedLogin', brandsError);

        await dispatch('setUserRoutes');
        dispatch('setAccessLevel');
        bus.$emit('set-baseUrl');
        router.push(getters.getDefaultRoute);
      } catch (e) {
        bus.$emit('trigger-global-error');
        console.error(e);
      }
    },
    async login({ commit, dispatch, getters }, credentials) {
      commit('LOGIN');

      const formData = new FormData();
      const isAccountApp = i18n.global.t('login.permission-error');
      const brandsError = i18n.global.t('login.brands-error');

      formData.append('login', credentials.email);
      formData.append('password', credentials.password);
      formData.append('mfa_code', credentials.mfa);

      if (h.getFromStorage('uuid')) formData.append('uuid', h.getFromStorage('uuid'));

      try {
        const login = await postLogin(formData);
        if (!login) return bus.$emit('trigger-global-error');

        commit('LOGIN_SUCCESS', login.data, getters.currency);
        if (getters.isAccessLevelApp) return dispatch('setFailedLogin', isAccountApp);

        h.onChangeLocale(login.data.user_language, getters.currency);

        await dispatch('setUserBrands');
        const { id, endpoint } = getters?.brands?.[0];
        commit('SELECT_BRAND_ENDPOINT', endpoint);
        await Promise.all([dispatch('setBrandStores', id), dispatch('setBrandOptions', id)]);

        if (!getters.brands.length) return dispatch('setFailedLogin', brandsError);

        await dispatch('setUserRoutes');
        dispatch('setAccessLevel');
        bus.$emit('set-baseUrl');
        router.push(getters.getDefaultRoute);
      } catch (e) {
        bus.$emit('trigger-global-error');
        console.error(e);
      }
    },
    forgot(instance, email) {
      const formData = new FormData();
      formData.append('userEmail', email);
      return postForgotLogin(formData);
    },
    email({ commit }, email) {
      commit('EMAIL', email);
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    acceptTermsOfService({ commit }) {
      commit('UPDATE_TERMS_OF_SERVICE', true);
      return acceptTos();
    },
    changeToken({ commit }, token) {
      commit('TOKEN', token);
    },
    changeDates({ commit }, dates) {
      commit('DATES', dates);
    },
    async changeBrand({ dispatch, commit }, brand) {
      bus.$emit('start-change-brand');
      try {
        const { id, endpoint } = brand;
        commit('SELECT_BRAND_ENDPOINT', endpoint);
        await Promise.all([dispatch('setBrandStores', id), dispatch('setBrandOptions', id)]);
      } catch (e) {
        bus.$emit('trigger-global-error');
        console.error(e);
      } finally {
        await dispatch('setUserRoutes');
        bus.$emit('finish-change-brand');
      }
      dispatch('setAccessLevel');
    },
    changeSegment({ commit }, segment) {
      commit('SELECT_SEGMENT', segment);
    },
    changeFilter({ commit }, filter) {
      commit('SELECT_FILTER', filter);
    },
    changePersona({ commit }, persona) {
      commit('SELECT_PERSONA', persona);
    },
    changeLanguage({ commit }, language) {
      commit('LANGUAGE', language);

      h.onChangeLocale(language);
    },
    changeAttributionInterval({ commit }, attributionInterval) {
      commit('SELECT_ATTRIBUTION_INTERVAL', attributionInterval);
    },
    changeAttributionType({ commit }, attributionType) {
      commit('SELECT_ATTRIBUTION_TYPE', attributionType);
    },
    changeExpInvalidEmail({ commit }, expInvalidEmail) {
      commit('UPDATE_EXP_INVALID_EMAIL', expInvalidEmail);
    },
    changeExpInvalidCellphone({ commit }, expInvalidCellphone) {
      commit('UPDATE_EXP_INVALID_CELLPHONE', expInvalidCellphone);
    },
    changeExpInvalidPhone({ commit }, expInvalidPhone) {
      commit('UPDATE_EXP_INVALID_PHONE', expInvalidPhone);
    },
    changeRecency({ commit }, recency) {
      commit('UPDATE_RECENCY', recency);
    },
    changeGeneralSuggestionsLimit({ commit }, generalSuggestionsLimit) {
      commit('UPDATE_GENERAL_SUGGESTIONS_LIMIT', generalSuggestionsLimit);
    },
    changeQuarantinePeriod({ commit }, quarantinePeriod) {
      commit('UPDATE_QUARANTINE_PERIOD', quarantinePeriod);
    },
    changeSegments({ commit }, segments) {
      commit('UPDATE_SEGMENTS', segments);
    },
    changePersonas({ commit }, personas) {
      commit('UPDATE_PERSONAS', personas);
    },
    changeSegmentsColors({ commit }, colors) {
      commit('UPDATE_SEGMENTS_COLORS', colors);
    },
    setUserRoutes({ commit }) {
      return getRoutes().then(({ data }) => {
        commit('UPDATE_ROUTES', data.routes);
        commit('UPDATE_DEFAULT_ROUTE', data.default);
      });
    },
    setRouteFallback({ getters, dispatch }) {
      const brands = getters.brands;
      if (!brands) {
        router.push('/');
        return;
      }

      const isDisabledBrand = !brands.map(item => item.brand).includes(getters.selectedBrand.brand);
      if (isDisabledBrand) {
        // filter brands that is not selected
        const brand = brands?.filter(el => el.brand !== getters.selectedBrand.brand)[0];
        // // emit event to refresh brands and routes
        bus.$emit('sidebar-brands-fallback', brand);
        dispatch('logout');
      }
    },
    async setUserBrands({ commit, dispatch }) {
      try {
        const response = await getUserBrands();
        const allBrands = response?.data?.data;
        commit('UPDATE_BRANDS', allBrands ?? null);
      } catch (e) {
        console.error(e);
        dispatch('logout');
      }
    },
    async setBrandStores({ commit, dispatch, getters }, brandId) {
      try {
        const response = await getBrandStores({ brand: brandId });
        const stores = response?.data?.data;
        const selectedBrandWithStores = { ...getters.selectedBrand, stores };

        commit('SELECT_BRAND', selectedBrandWithStores ?? null);
      } catch (e) {
        console.error(e);
        dispatch('logout');
      }
    },
    async setBrandOptions({ commit, dispatch, getters }, brandId) {
      try {
        const response = await getBrandOptions({ brand: brandId });
        const options = response?.data?.data;
        const selectedBrandWithOptions = { ...getters.selectedBrand, ...options };

        commit('SELECT_BRAND', selectedBrandWithOptions);
      } catch (e) {
        console.error(e);
        dispatch('logout');
      }
    },
    setFailedLogin({ dispatch }, message) {
      dispatch('logout');
      bus.$emit('trigger-global-error');
      bus.$emit('login-error', i18n.global.t(message));
      return;
    },
    setAccessLevel({ commit }) {
      getAccessLevel().then(response => {
        const data = response?.data;
        const accessLevel = [data?.data];
        if (data?.success) commit('SET_ACCESS', accessLevel);
        bus.$emit('dismiss-toasts');
      });
    },
    setFeatureFlags({ commit }, featureFlags) {
      commit('SET_FEATURE_FLAGS', featureFlags);
    },
  },
  getters: {
    isLoggedIn: state => !!(state.token && state.tokenV3 && state.brands && state.user),
    isAccessLevelApp: state => state.user?.accessType === accessType.app,
    hasBrands: state => state.brands?.length,
    token: state => state.token,
    tokenV3: state => state.tokenV3,
    selectedBrand: state => {
      if (state.brands) {
        return state.selectedBrand || state.brands[0];
      }
      return {};
    },
    selectedBrandEndpoint: state => state?.selectedBrand?.endpoint,
    currency: (_, getters) => getters.selectedBrand?.currency,
    ready: state => state.ready,
    user: state => state.user,
    hasSso: state => !!state.user?.federation?.id || false,
    brands: state => state.brands || [],
    dates: state => state.dates,
    selectedPersona: state => state.selectedPersona,
    selectedFilter: state => state.selectedFilter,
    selectedSegment: state => state.selectedSegment,
    attributionInterval: state => state.attributionInterval,
    attributionType: state => state.attributionType,
    expInvalidEmail: state => state.expInvalidEmail,
    expInvalidCellphone: state => state.expInvalidCellphone,
    expInvalidPhone: state => state.expInvalidPhone,
    recency: state => state.recency,
    generalSuggestionsLimit: state => state.generalSuggestionsLimit,
    personas: state => state.personas,
    segments: state => state.segments,
    segmentsColors: state => state.segmentsColors,
    getLastEmail: state => state.lastEmail,
    getDefaultRoute: state => menuItems.find(el => el.id === state.brandDefaultRoute)?.url || '/',
    getRoutes: state => state.brandRoutes,
    getAccessLevel: state => state.accessLevel,
    getHasCampaignApproval: state =>
      state?.selectedBrand ? state?.selectedBrand?.hasCampaignApproval : state?.brands[0]?.hasCampaignApproval,
    customerDistribution: state => state?.selectedBrand?.customerDistribution,
    getSearchableList: state => state?.selectedBrand?.searchColumns?.searchable,
    quarantinePeriod: state => state.quarantinePeriod,
    getDifferenceInDays: state => {
      const initialDay = moment(state.dates.startDate);
      const finalDay = moment(state.dates.endDate);
      const differenceInDays = finalDay.diff(initialDay, 'days');
      return differenceInDays;
    },
    getFeatureFlags: state => state.featureFlags,
    getEnabledSellersWarning: state => state?.selectedBrand?.enabledSellersWarning,
    getEnabledOtoScore: state => state?.selectedBrand?.enableAudiencePotential,
    getEnabledEnrollmentFix: state => state?.selectedBrand?.enrolmentFix,
    getLookback: state => state?.selectedBrand?.lookback,
  },
};

export default auth;
