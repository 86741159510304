import moment from 'moment';

export default {
  name: 'pt-br',
  el: {
    colorpicker: {
      confirm: 'Confirmar',
      clear: 'Limpar',
    },
    datepicker: {
      now: 'Agora',
      today: 'Hoje',
      cancel: 'Cancelar',
      clear: 'Limpar',
      confirm: 'Confirmar',
      selectDate: 'Selecione a data',
      selectTime: 'Selecione a hora',
      startDate: 'Data inicial',
      startTime: 'Hora inicial',
      endDate: 'Data final',
      endTime: 'Hora final',
      prevYear: 'Ano anterior',
      nextYear: 'Próximo ano',
      prevMonth: 'Mês anterior',
      nextMonth: 'Próximo mês',
      year: '',
      month1: 'Janeiro',
      month2: 'Fevereiro',
      month3: 'Março',
      month4: 'Abril',
      month5: 'Maio',
      month6: 'Junho',
      month7: 'Julho',
      month8: 'Agosto',
      month9: 'Setembro',
      month10: 'Outubro',
      month11: 'Novembro',
      month12: 'Dezembro',
      // week: 'semana',
      weeks: {
        sun: 'Dom',
        mon: 'Seg',
        tue: 'Ter',
        wed: 'Qua',
        thu: 'Qui',
        fri: 'Sex',
        sat: 'Sab',
      },
      months: {
        jan: 'Jan',
        feb: 'Fev',
        mar: 'Mar',
        apr: 'Abr',
        may: 'Mai',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ago',
        sep: 'Set',
        oct: 'Out',
        nov: 'Nov',
        dec: 'Dez',
      },
    },
    shortcuts: [
      {
        text: 'Ontem',
        value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        key: 'yesterday',
      },
      {
        text: 'Últimos 7 dias',
        value: [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
        key: 'lastSevenDays',
      },
      {
        text: 'Últimos 30 dias',
        value: [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
        key: 'lastThirtyDays',
      },
      {
        text: 'Este mês',
        value: [moment().subtract(1, 'days').startOf('month'), moment().subtract(1, 'days').endOf('month')],
        key: 'thisMonth',
      },
      {
        text: 'Último mês',
        value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        key: 'lastMonth',
      },
      {
        text: 'Últimos 6 meses',
        value: [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        key: 'lastSixMonths',
      },
      {
        text: 'Este ano',
        value: [moment().startOf('year'), moment().subtract(1, 'days')],
        key: 'thisYear',
      },
      {
        text: 'Último ano',
        value: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        key: 'lastYear',
      },
      {
        text: '12 meses + atual',
        value: [moment().subtract(12, 'months').startOf('month'), moment().subtract(1, 'days')],
        key: 'twelveMonthsPlusCurrent',
      },
    ],
  },
};
